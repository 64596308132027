<template>
  <grid
    type="user"
    title="Users"
    :multiple="true"
    :permanentFilters="{
      company_ids: `INSET${$store.state.session.company.company_id}`
    }"
    :isMain="isMain"
    :visible="['user_preview', 'user_status', 'user_phone', 'user_email']"
  >
  </grid>
</template>

<script>
import Grid from '../ui/Grid/Grid.vue'
import BodyMixin from '../mixins/Body'

export default {
  name: '',
  data() {
    return {}
  },
  mixins: [BodyMixin],
  components: {
    Grid
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
