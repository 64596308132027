<template>
  <Page full>
    <user-body />
  </Page>
</template>

<script>
import PageMixin from '../mixins/Page'
import UserBody from '../bodies/Users.vue'

export default {
  mixins: [PageMixin],
  name: '',
  data() {
    return {
      startingTab: 'Users'
    }
  },
  components: {
    UserBody
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
